import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    playing: false,
    kater:"",
    jingle:"",
    actualTime: 0,
  },
  mutations: {

    assignsong(state){
      if (state.kater === ""){
        state.kater = new Audio('https://undicht.be/api/kater.mp3');
      }
    },

    playpause (state, playpause) {
      state.playing = !state.playing;
      if (state.playing){
        console.log("play");
        state.kater.play();

      } else {
        console.log("pause");
        state.kater.pause();
        console.log(state.kater.duration)
        console.log(state.kater.volume)
        console.log(state.kater.currentTime)
      }
    },

    playjingle(state, payload){
      if (payload == 1){
        state.jingle = new Audio('https://undicht.be/api/Baschi.mp3');
      } else if (payload == 2){
        state.jingle = new Audio('https://undicht.be/api/Til.mp3');
      } else if (payload == 3){
        state.jingle = new Audio('https://undicht.be/api/maege.mp3');
      } else if (payload == 4){
        state.jingle = new Audio('https://undicht.be/api/loeru.mp3');
      } else if (payload == 5){
        state.jingle = new Audio('https://undicht.be/api/Sebi.mp3');
      }

      state.jingle.play();

    },
  },
  actions: {

  },
  getters: {
    currentPlayTime(state, getters){
      console.log(state.kater.currentTime);
      return state.kater.currentTime;
    },

  }
});
