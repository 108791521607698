<template>

  <div class="container">

    <img class="image-cover" src="../assets/nacktsy.jpg" alt="">

    <router-link v-on:click.native="playjingle(1)" class="router" id="route-gigs" tag="div" to="/gigs"> </router-link>
    <router-link v-on:click.native="playjingle(2)" class="router" id="route-musik" tag="div" to="/musik"> </router-link>
    <router-link v-on:click.native="playjingle(3)" class="router" id="route-galerie" tag="div" to="/galerie"> </router-link>
    <router-link v-on:click.native="playjingle(4)" class="router" id="route-vigf" tag="div" to="/vigf"> </router-link>
    <router-link v-on:click.native="playjingle(5)" class="router" id="route-kontakt" tag="div" to="/kontakt"> </router-link>

  </div>



</template>

<script>
export default {
  name: 'Navigation',
  props: {

  },
  methods: {
    playjingle(index){
      this.$store.commit('playjingle', index);
    },

  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.container {
  position: relative;
  width: 500px;
  margin: auto;
  // background-color: blue;
}

.image-cover{
  min-width: 500px;
  width: 50%;
}

#route-gigs{
  width:16%;
  height:50%;
  // background:rgba(100,255,255,0.5);
  top:30%;
  left:82%;
}

#route-musik{
  width:25%;
  height:50%;
  // background:rgba(100,255,255,0.5);
  top:30%;
  left:0%
}

#route-galerie{
  width:20%;
  height:30%;
  // background:rgba(100,255,255,0.5);
  top:30%;
  left:25%
}

#route-vigf{
  width:16%;
  height:40%;
  // background:rgba(100,255,255,0.5);
  top:30%;
  left:50%
}

#route-kontakt{
  width:19%;
  height:40%;
  // background:rgba(100,255,255,0.5);
  top:30%;
  left:65%
}

.router{
  position: absolute;
  z-index: 1;
  border: none;
  text-align: center;
  cursor: pointer;
}

@media only screen and (max-width: 700px) {

  .container {
    position: relative;
    width: 100%;
    margin: auto;
    // background-color: blue;
  }

  .image-cover{
    width: 90%;
    min-width: 0px;
  }

}


</style>
