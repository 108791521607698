<template>
  <div class="home">

    <Navigation class="navigation"> </Navigation>

    <!-- <Nacktsy> </Nacktsy> -->

    <Footer> </Footer>

  </div>
</template>

<script>
// @ is an alias to /src
import Navigation from '@/components/Navigation.vue'
import Footer from '@/components/Footer.vue'
import Nacktsy from '@/components/Nacktsy.vue'


export default {
  name: 'home',
  components: {
    Navigation,
    Footer,
    Nacktsy
  },
  methods: {

    fetchContent(){

      fetch('https://undicht.be/api/gig.json')  .then((result) => {
        return result.json();
      }).then((data) => {

        var data = data;
        // console.log(data[0].acf.date);
        // console.log(data[1].acf.date);
        //
        // console.log(data[0].acf.name);
        // console.log(data[1].acf.name);

      });

    },

  },
  mounted: function(){
    this.fetchContent();
  },
}


</script>

<style scoped lang="scss">

.home{

}

.navigation {
  margin-bottom: 150px;
}



</style>
