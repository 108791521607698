<template>

  <div class="container">

    <img class="image-nav" src="../assets/nav_alle_small.png" alt="Die Berner Band Uñdicht lächelt in die Kamera.">

    <router-link v-on:click.native="playjingle(1)" class="router" id="route-gigs" tag="div" to="/gigs"> </router-link>
    <router-link v-on:click.native="playjingle(2)" class="router" id="route-musik" tag="div" to="/musik"> </router-link>
    <router-link v-on:click.native="playjingle(3)" class="router" id="route-galerie" tag="div" to="/galerie"> </router-link>
    <router-link v-on:click.native="playjingle(4)" class="router" id="route-vigf" tag="div" to="/vigf"> </router-link>
    <router-link v-on:click.native="playjingle(5)" class="router" id="route-kontakt" tag="div" to="/kontakt"> </router-link>

  </div>

</template>

<script>
export default {
  name: 'Navigation',
  props: {

  },
  methods: {
    playjingle(index){
      this.$store.commit('playjingle', index);
    },

  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.container {
  position: relative;
  width: 800px;
  margin: auto;
  // background-color: blue;
}

#route-gigs{
  width:23%;
  height:90%;
  // background:rgba(255,255,255,0.5);
  top:5%;
  left:5%;
}

#route-musik{
  width:15%;
  height:90%;
  // background:rgba(255,255,255,0.5);
  top:5%;
  left:28%
}

#route-galerie{
  width:15%;
  height:90%;
  // background:rgba(255,255,255,0.5);
  top:5%;
  left:43%
}

#route-vigf{
  width:16%;
  height:90%;
  // background:rgba(255,255,255,0.5);
  top:5%;
  left:58%
}

#route-kontakt{
  width:19%;
  height:90%;
  // background:rgba(255,255,255,0.5);
  top:5%;
  left:74%
}

.router{
  position: absolute;
  z-index: 1;
  border: none;
  text-align: center;
  cursor: pointer;
}

@media only screen and (max-width: 700px) {

  .container {
    position: relative;
    width: 100%;
    margin: auto;
    // background-color: blue;
  }

}


</style>
