<template>
  <div v-if="!cookie" class="footer">

    <div class="column-one">
      <p>Wir verwenden Cookies um zu kontrollieren, dass du auch wirklich an unser Konzert kommst.</p>
    </div>

    <div class="column-two">
      <button type="button" name="button" @click="setcookie()"> Ok Cool </button>
    </div>

  </div>

</template>

<script>
export default {
  name: 'Footer',
  props: {

  },
  data() {
    return {
      cookie: false,

    }
  },
  methods: {

    getcookie(){

      var guetzli = localStorage.getItem("cookie");
      // console.log("Z guezli isch " + guetzli);
      // console.log(this.cookie);

      if (guetzli){
        this.cookie = true;
      }

    },

    setcookie(){
      this.cookie = true;

      localStorage.setItem("cookie", true);
      console.log(localStorage.getItem("cookie"));

    }

  },
  mounted: function(){
    this.getcookie();
  },

  computed: {

  },
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.footer{

  display: flex;

  height:60px;
  width: 60%;
  position: fixed;
  bottom: 0;
  left: 20%;
  background-color: lighten(red, 30%);
}

p{
  font-size: 1rem !important;
}

.column-one{
  width: 80%;
}

.column-two{
  width: 20%;
}
button {
  border: none;
  margin-left: 10px;
  height: 40px;
  border-radius: 5px;
  cursor: pointer;
  background-color: white;
}

@media only screen and (max-width: 550px) {

  .footer{
    height:100px;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: lighten(red, 30%);
  }

  p{
    font-size: 0.8rem !important;
  }

}

</style>
